import React from 'react';

function ProjectsSection() {
  const projects = [
    {
      title: 'Proje Adı 1',
      description: 'Proje ile ilgili kısa bir açıklama.',
      image: 'proje1.jpg',
    },
    {
      title: 'Proje Adı 2',
      description: 'Proje ile ilgili kısa bir açıklama.',
      image: 'proje2.jpg',
    },
    // Diğer projelerinizi ekleyin
  ];

  return (
    <section id="projects" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-8">Projelerim</h2>
        <div className="flex flex-wrap -mx-4">
          {projects.map((project, index) => (
            <div
              key={index}
              className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8"
              data-aos="fade-up"
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                  <p className="text-gray-700">{project.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectsSection;
