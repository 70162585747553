import React from 'react';

function AboutSection() {
  return (
    <section id="about" className="py-20 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-8">Hakkımda</h2>
        <p className="text-lg text-gray-700 max-w-3xl mx-auto">
            Ben Uğur Ulupınar. Gazi Üniversitesi Bilgisayar Mühendisliği Bölümünden 2023 yılında mezun oldum. Öncelikle web alanında frontend, backend ve server tarafında çalışmalar yaptım.
            Daha sonra aldığım karar ile gömülü yazılım alanına yöneldim ve halihazırda gömülü yazılım üzerinde çalışıyorum.
        </p>
      </div>
    </section>
  );
}

export default AboutSection;
