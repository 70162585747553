import React from 'react';

function HeroSection() {
  return (
    <section
      id="home"
      className="bg-gradient-to-r from-blue-600 to-blue-400 h-screen flex items-center"
    >
      <div className="container mx-auto text-center text-white">
        <h1 className="text-5xl font-bold mb-4">Merhaba, Ben Uğur Ulupınar</h1>
        <p className="text-2xl">Gömülü Yazılım Geliştiricisi</p>
      </div>
    </section>
  );
}

export default HeroSection;
