import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

function ContactSection() {
  return (
    <section id="contact" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-8">İletişim</h2>
        <div className="flex justify-center space-x-8 text-4xl text-gray-700">
          <a
            href="mailto:ugur.ulupinar2102@gmail.com"
            className="hover:text-blue-600 transition duration-300"
            data-aos="fade-up"
          >
            <FaEnvelope />
          </a>
          <a
            href="https://github.com/ugurulupinar"
            className="hover:text-blue-600 transition duration-300"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/ugurulupinar2102/"
            className="hover:text-blue-600 transition duration-300"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
