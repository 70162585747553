import React from 'react';

function SkillsSection() {
  const skills = [
    'JavaScript',
    'C',
    'Embedded Software',
    'Linux',
    // Diğer yeteneklerinizi ekleyin
  ];

  return (
    <section id="skills" className="py-20 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-blue-600 mb-8">Yeteneklerim</h2>
        <div className="flex flex-wrap justify-center">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="bg-gray-100 w-40 h-40 flex items-center justify-center m-4 rounded-full shadow-lg text-xl font-semibold text-gray-700 hover:bg-blue-600 hover:text-white transition duration-300"
              data-aos="flip-left"
              data-aos-delay={index * 100}
            >
              {skill}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SkillsSection;
