import React from 'react';

function Navbar() {
  return (
    <nav className="bg-white fixed w-full z-10 top-0 shadow">
      <div className="container mx-auto px-4 flex items-center justify-between h-16">
        <a href="#home" className="text-2xl font-bold text-blue-600">
          Uğur Ulupınar
        </a>
        <div className="hidden md:flex space-x-6">
          <a href="#about" className="text-gray-700 hover:text-blue-600">
            Hakkımda
          </a>
          {/* <a href="#projects" className="text-gray-700 hover:text-blue-600">
            Projeler
          </a> */}
          <a href="#skills" className="text-gray-700 hover:text-blue-600">
            Yetenekler
          </a>
          <a href="#contact" className="text-gray-700 hover:text-blue-600">
            İletişim
          </a>
        </div>
        {/* Mobil Menü İkonu */}
        <div className="md:hidden">
          {/* Buraya mobil menü ikonu ve fonksiyonu ekleyebilirsiniz */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
